/*eslint-disable*/
const en = {
  languageId: "English",
  languageName: "English",
  global: {
    defaultBackLinkText: "Back to Previous",
    yes: "Yes",
    no: "No",
    menu: {
      label: "Menu",
      accountInfo: "Account Info",
      biography: "@:screens.PeerMentorBiography.pageTitle",
      agreements: "Agreements",
      signOut: "Sign Out"
    },
    buttons: {
      goBack: "Go back",
      update: "Update",
      returnHome: "Return Home",
      save: "Save",
      editNotes: "Edit Notes",
      saveUpdate: "Save/Update",
      ok: "OK",
      share: "Share",
      close: "Close",
      goToLogin: "Go to login screen",
      submit: "Submit",
      proceed: "Proceed",
      sendProceed: "Send and Proceed",
      goHome: "Go to Home Screen",
      skip: "Skip",
      updatePassword: "Update Password",
      stillHere: "I'm still here",
      logOut: "Log out",
      sendVerification: "Send Verification",
      beginApplication: "Begin Application",
      cancel: "Cancel",
      upload: "Upload",
      delete: "Delete",
      acknowledge: "Acknowledge"
    },
    errorMessages: {
      errorUsedPassword:
        "You have used this password before. You may not re-use any of the last five passwords.",
      incorrectCurrentPassword: "Incorrect current password",
      passwordExpirationWarning: "Password expiring in {num} days.",
      passwordExpirationLinkText: "Update Password",
      emailTaken: "There is already an account with this email address."
    },
    passwordExpired: {
      header: "Password Expired",
      message:
        "To keep you and your information safe, we require that you update your password every {num} days. Feel free to update it now."
    },
    sessionTimeoutWarning: {
      header: "Timeout warning",
      message: "You will be logged out due to inactivity in {time}",
      stillHere: "Let us know if you're still here."
    }
  },
  fieldLabels: {
    datepickerPlaceholder: "Click to select",
    select: "Select",
    languagePref: "Preferred Language:",
    email: "Email",
    emailAddress: "Email Address",
    currentPassword: "Current Password",
    password: "Password",
    newPassword: "New Password",
    passwordRequirementsHeader: "Password Requirements:",
    passwordRequirements: {
      uppercase: "At least one uppercase letter",
      lowercase: "At least one lowercase letter",
      symbol: "At least one symbol",
      numbers: "At least one number",
      length: "Between 12 and 60 characters",
      spaces: "Does not contain spaces"
    },
    passwordRecencyRequirement:
      "Can't use any of the five most recent passwords",
    passwordShowAria: "Show password",
    passwordHideAria: "Hide password",
    resetPassword: "Create a New Password",
    fieldShow: "show",
    fieldHide: "hide",
    name: "Name",
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone",
    mobilePhone: "Mobile Phone Number",
    city: "City",
    state: "State",
    age: "Age",
    gender: "Gender",
    language: "@:screens.PeerMentorAccountInfoLanguage.pageTitle",
    sessionNotes: "Session Notes",
    sessionNotePeer: "Which {peer} is this note for?",
    sessionNoteReason: "Why did you meet?",
    sessionNoteNotes: "Add notes from {peer} meeting",
    sessionNoteNotesView: "Notes from Meeting",
    sessionNoteDurationDate: "Session Duration and Date",
    sessionNoteDuration: "Meeting Duration",
    sessionNoteDateFieldset: "Date",
    sessionNoteDate: "Meeting Date",
    sessionNoteDurationMinutes: "{num} minutes",
    sessionNoteUrgent: "{peer} needs follow up by Care Team.",
    sessionNoteLeaving: "{peer} at-risk of leaving for another provider.",
    sessionNotePurpose: "Meeting purpose",
    sendMessage: "Type a Message (Chats with {users} are Confidential)",
    sendMessageCoord: "Type a Message",
    notes: "Notes",
    day: "day",
    date: "date",
    whichPeer: "Which {peer} do you want to share it with?",
    selectPeer: "Select {peer}",
    numPeers: "{num} {peers} selected",
    selectAll: "Select All",
    addShareNote: "Add a note about why are you are sharing this:",
    enterEmail: "Enter Your Email Address",
    surveyHate: "Hate It",
    surveyLove: "Love It",
    surveyAwful: "Awful",
    surveyFantastic: "Fantastic",
    surveyCommentsFeel: "Any comments on why you feel like this?",
    surveyCommentsRating: "Any comments on why you give it this rating?",
    notificationEmail: "Email Notifications",
    notificationSms: "SMS Notifications",
    adminNotes: "Admin Notes",
    emailZoom: "Email for Zoom invite"
  },
  screens: {
    Login: {
      greeting: "Welcome! We're glad you're here.",
      forgotPassword: "Forgot Password?",
      btnLogIn: "Log In",
      errorBadCreds: "Unknown password or email address",
      errorLockedAccount: "Your account has been locked due to too many failed login attempts. Please check your email for more details.",
      errorPasswordExpired: "Your password has expired. Please reset you password using the Forgot Password link below.",
      noAccount: "Don't have an account?",
      signUp: "Sign Up"

    },
    ResendInvitation: {
      enterEmail: "Enter your email address to receive an invitation to enroll.",
      enroll: "Enroll in program",
      emailConfirmation1: "If your email is in the system, you will receive an email with an enrollment link.",
      emailConfirmation2: "Click the link in your email, create a password, and then use your new password to log in."
    },
    Apply: {
      pageTitle: "Apply",
      menteeHeader: "Apply To Be Mentored",
      mentorHeader: "Apply To Be A Mentor",
      mentee: {
        emailLabel: "Before you apply to be mentored, let’s first verify your email address."
      },
      mentor: {
        emailLabel: "Before you apply to be a mentor, let’s first verify your email address."
      },
      emailConfirmation1: "We’ve sent you an email verification link to your inbox. You should receive it immediately.",
      emailConfirmation2: "Please find the email and click the button, “Verify Email Address” to complete the verification process.",
      emailConfirmationTroubleHeader: "Having Trouble?",
      emailConfirmationTrouble1: "If you are not seeing the email in your inbox, please check your spam folder.",
      emailConfirmationTrouble2: "If you still haven’t received the verification email, you can choose one of these options:",
      emailConfirmationTroubleReenter: "Re-enter my email address and send new verification",
      emailSubmittedHeader: "Success! Email Address Verified",
      emailSubmitted: "Thank you for verifying your email address. Click the button below to begin your application to be mentored."
    },
    Interstitial: {
      termsHeader: "Terms & Conditions",
      termsAgree: "Yes, I have read and agree to the following:",
      joinAgreement: "Agreement to Join",
      volunteerAgreement: "Volunteer Agreement",
      privacy: "Privacy Policy",
      license: "End User License Agreement",
      btnSubmit: "Create My Account"
    },
    PeerMentorHome: {
      pageTitle: "Home",
      coordinatorMessaging: "Chat with {coordinators}",
      mentorMenteesLabel: "Your {MentorORPeerORPeers}",
      noMatchMessage: "Waiting for {MentorORPeer} match",
      mentorQuestionsShort: "Prep for Mentor Mtg",
      mentorQuestionsLong: "Prepare for Mentor Meeting",
      addSessionNotes: "Add Session Notes",
      resourcesLabel: "@:screens.ResourcesList.resourcesLabel",
      healthLibrary: "@:screens.ResourcesList.healthLibrary",
      communityResources: "@:screens.ResourcesList.communityResources",
      surveyLink: "Make a difference with your feedback",
      surveyAcknowledgement: "Thank you for your feedback!",
      menteeDisclaimer: "Disclaimer: Do not rely on your Peer Mentor for medical advice. Please contact your doctor for specific questions regarding your medical care. If this is an emergency, please dial 911 or go to your nearest Emergency Room.",
      mentorDisclaimer: "Reminder: Do not offer the Peer Mentee medical advice. Please have them contact their doctor for specific questions regarding their medical care. If this is an emergency, please ask them to dial 911 or go to the nearest Emergency Room.",
      notYetEnrolled: "Not yet enrolled",
      videoStories: "Patient Video Stories",
      viewAllVideos: "View all Video Stories",
      viewFewerVideos: "View fewer Video Stories",
      careGuides: "Care Guides"
    },
    PeerMeetingPrep: {
      pageTitle: "Prepare For Your Meeting",
      headerTitle: "Prepare For Your {Mentor} Meeting",
      concernsHeader: "Your Concerns",
      orderDescription: "(Most important to least important)",
      questionsHeader: "Your Questions & Priorities",
      notesLabel: "Additional Notes",
      changesSaved: "Changes saved",
      linkTitle: "Prepare for Your Mentor Meeting"
    },
    DoctorMeetingPrep: {
      headerTitle: "Prepare For Your Doctor Meeting",
      prioritiesHeader: "Your Priorities For the Visit"
    },
    PeerMentorVideos: {
      pageTitle: "Video Story",
    },
    ResourcesList: {
      pageTitle: "Health Library",
      pageTitleCommunity: "Community Resources",
      resourcesLabel: "Resources",
      healthLibrary: "Health Resources",
      tabAllArticles: "All Articles",
      tabShared: "Shared With Me",
      communityResources: "Community Resources",
      noSharedArticles:
        "No articles shared with you yet. Feel free to browse through",
      allArticlesLinkText: "all articles",
      shareHeader: "Share This Article with Your {MentorORPeers}",
      modalConfirmationHeader: "You have shared this article",
      viewArticle: "View Article"
    },
    Tips: {
      pageTitleMentor: "Mentoring Tips",
      pageTitlePeer: "Manage Your Health",
      mentorTipsHeader: "Helpful Mentoring Tips",
      menteeTipsHeader: "Helpful Tips",
      viewAllTips: "View All Tips",
      tipCounter: "Tip {num}",
      previousTip: "Previous Tip",
      nextTip: "Next Tip",
    },
    PeerMentorAccountInfo: {
      pageTitle: "Account Information",
      backLinkText: "Back to Main Screen",
      accountInfoHeader: "Account Information",
      managePersonalLinkText:
        "@:screens.PeerMentorAccountInfoPersonal.pageTitle",
      passwordHeader: "Your Password",
      managePasswordLinkText:
        "@:screens.PeerMentorAccountInfoPassword.pageTitle",
      notificationsHeader: "Notifications",
      manageNotificationsLinkText:
        "@:screens.PeerMentorAccountInfoNotifications.pageTitle",
      languageHeader: "@:screens.PeerMentorAccountInfoLanguage.pageTitle",
      manageLanguageLinkText: "Change Language",
      notificationsOn:
        "@:screens.PeerMentorAccountInfoNotifications.notificationsOn",
      notificationsOff:
        "@:screens.PeerMentorAccountInfoNotifications.notificationsOff",
      yourPhoto: "Your Photo",
      addPhoto: "Add Your Photo",
      availability: "Availability",
      available: "Currently available",
      unavailable: "Currently unavailable"
    },
    PeerMentorAccountInfoPersonal: {
      pageTitle: "Update Personal Info",
      backLinkText: "Back to Account Information",
      pageHeader: "@:screens.PeerMentorAccountInfoPersonal.pageTitle",
      modalConfirmationHeader: "Profile updated"
    },
    PeerMentorAccountInfoPassword: {
      pageTitle: "Manage Password",
      backLinkText: "@:screens.PeerMentorAccountInfoPersonal.backLinkText",
      pageHeader: "@:screens.PeerMentorAccountInfoPassword.pageTitle",
      modalConfirmationHeader: "Password updated"
    },
    PeerMentorAccountInfoNotifications: {
      pageTitle: "Manage Notifications",
      backLinkText: "@:screens.PeerMentorAccountInfoPersonal.backLinkText",
      pageHeader: "@:screens.PeerMentorAccountInfoNotifications.pageTitle",
      modalConfirmationHeader: "Preferences updated",
      notificationsOn: "On",
      notificationsOff: "Off"
    },
    PeerMentorAccountInfoLanguage: {
      pageTitle: "Language Preference",
      backLinkText: "@:screens.PeerMentorAccountInfoPersonal.backLinkText",
      pageHeader: "@:screens.PeerMentorAccountInfoLanguage.pageTitle",
      modalConfirmationHeader: "Preferences updated"
    },
    PeerMentorBiography: {
      pageTitle: "Biography",
      editLink: "Edit my biography",
      concerns: "My concerns",
      concernsList: "My concerns list",
      happy: "What Makes Me Happy",
      priorities: "My Priorities and Goals",
      barriers: "Biggest Barriers to Staying Healthy",
      about_me: "About Me",
      mentorSince: "I’ve been a {mentor} since",
      journey: "My Journey, My Experience",
      why_mentor: "Why do I love to mentor?",
      view: "View Biography",
      backToDetails: "Back to Details",
      concernsTab: "Concerns",
      concernsHeader: "What Areas of Concern Do You Have Regarding Your Diagnosis?",
      checkAll: "Check all that apply",
      worries: "Biggest Pressures/Worries"
    },
    SessionNotesAdd: {
      pageTitle: "Add Session Notes for {coordinator}",
      backLinkText: "Back to Previous",
      pageHeader: "@:screens.SessionNotesAdd.pageTitle",
      modalConfirmationHeader: "Session Note Added",
      modalConfirmationAnotherLink: "Add another session note",
      topicsLabel: "Select which categories/topics you discussed",
      notesCharacterCount: "{current} out of {max} characters remaining",
      meetingTypeLabel: "Meeting Type",
      meetingTypes: {
        phoneCall: "Phone call",
        inPerson: "In-person meeting",
        chat: "Chat messaging session",
        videoAudio: "Video/Audio Call"
      },
      urgentWarning: "If this is an urgent matter that needs immediate consideration, please contact your {coord}. If this is an emergency, please ask them to dial 911 or go to the nearest Emergency Room."
    },
    PeerMentorMessaging: {
      pageTitle: "Chat",
      menteeCall: "Call Care Team for Medical Questions",
      mentorCall: "Medical Questions? Ask {peer} to Call Care Team.",
      tabChat: "@:screens.PeerMentorMessaging.pageTitle",
      tabDetails: "{userType} details",
      tabSessionNotes: "Session Notes",
      addSessionNote: "Add New Session Note",
      conversationStarter: "Example Conversation Starter:",
      copyText: "Copy this text to my message",
      dismiss: "Dismiss",
      notEnrolled: "This person is not yet enrolled. Please call to initiate engagement and nudge them to enroll. Thank you!",
      videoButtonDefaultText: "Start a Video Call"
    },
    MentorSessionNotesDetail: {
      pageTitle: "Session Note",
      backLinkText: "Back to Session Notes",
      duration: "{duration} minutes",
      topicsLabelView: "Meeting Topics",
    },
    Logout: {
      pageTitle: "Signed Out",
      pageHeader: "You Have Successfully Signed Out"
    },
    ForgotPassword: {
      pageTitle: "Forgot Password",
      backLinkText: "Back to Login Screen",
      pageHeader: "Forgot Password?"
    },
    ForgotPasswordConfirmation: {
      pageTitle: "@:screens.ForgotPassword.pageTitle",
      backLinkText: "@:screens.ForgotPassword.backLinkText",
      pageHeader: "Success",
      textSentPassword:
        "We've sent a password reset link to your email address.",
      textClickLink:
        "Click the link in your email, create a new password, and then use your new password to log in."
    },
    ForgotPasswordReset: {
      pageTitle: "@:screens.ForgotPasswordReset.pageHeader",
      pageHeader: "Reset Your Password",
      errorMessage: "Your password reset token was not recognized or has expired.",
      errorMessageHaveUsed: "You have used this password before."
    },
    NotFound: {
      pageTitle: "@:screens.NotFound.pageHeader",
      pageHeader: "Page Not Found",
      header: "Page Not Found",
      message:
        "Looks like you were attempting to access a page that doesn't exist."
    },
    Survey: {
      welcome: "Welcome back",
      ratingAriaDescription: "On a scale from 1 to {num}",
      modalConfirmationHeader: "Thank you",
      modalConfirmationText: "We have received your survey response"
    },
    Welcome: {
      welcome: "@:screens.Survey.welcome",
      ratingAriaDescription: "On a scale from 1 to {num}",
      thankYou: "Thank you for giving back!",
      makeDifference: "You’re making a big difference in people’s lives.",
      peopleMentored: "People Mentored | Person Mentored | People Mentored",
      serviceHours:
        " Total Service Hours | Total Service Hour | Total Service Hours"
    }
  },
  components: {
    Messaging: {
      firstMessagePrompt: "Say Hello to your new {userType}!",
      errorEmptyMessage:
        "Type one or more words and hit enter to send a message",
      surveyButton: "Answer 1 question"
    },
    UserDetails: {
      notesHeader: "Personal Notes",
      doNotText: "Please <strong style='color: red;'>do not</strong> text your {mentee}; all direct messaging should be done through the {app} chat."
    }
  }
};
export default en;
